.statement-container{
    background-color: whitesmoke;
    width: 90%;
    height: max-content;
    color: black;
  padding: 2.5rem;
}


.statement-header{
    
    border-bottom: 2px solid rgb(208, 208, 208);
    margin-bottom: 2rem;
    padding-bottom: 2rem;
}
.logo-statements{
    width: 150px;
    margin-left: 10px;
   
  
}

.logo-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    

}


.statement-date{
font-weight: bold;
font-size: 12px;
color: black;
}

.statement-container table{
    border: 1px solid rgb(216, 216, 216);
    padding: 1rem;
    width: 100%;
    text-align: left;
}

td{
    border: 1px solid rgb(216, 216, 216);
}

.action-btn{
    margin: 0 5px 0 5px !important;
   
}

.chart-container{
    border-top: 1px solid rgb(208, 208, 208);
    margin-top: 4rem;
   
}

.box-settings{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
   height: 100vh;
   
}
.footer-body{
    margin-top: 50px;
}
.footer-text{
    font-size: 10px;
    color: grey;
    font-weight: 400;
}

.username{
    font-size: 0.8rem;
    font-weight: 600;
    margin: 5px;
}