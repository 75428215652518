
  .chart-btn{
    margin: 0.5rem;
    width: 100px;
    padding: 8px;
    background-color: #142b7895;
    border: 1px solid rgba(255, 166, 0, 0.568);
    color: white;
    border-radius: 8px;

  }

  .chart-btn:hover{
    background-color: rgba(255, 166, 0, 0.768);
  }

  .active{
    background-color: rgba(255, 166, 0, 0.768);
  }