.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.nft-modal{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-image: linear-gradient(160deg,#000000 0%, #090e1f  100%);

margin: 1rem;
padding: 1rem;

  position: absolute;
  margin: auto;
  z-index: 9999;
  height: 100%;
  width: 100%;

}


.nft-box{
  margin:1rem;
  padding:1rem;
  border:2px solid #282c34;
  width: 200px;
  border-radius: 10px;
  background-color:#042566c7 ;
  display: flex;
  flex-direction: column;
  

}

button{
  cursor: pointer;
}

.card{
  background-color: aliceblue;
  color: rgb(0, 5, 38);
  border-radius: 10px;
  padding: 1rem;
 
}

.flex-col{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.card p{
  font-weight: 600;
  font-size: 12px;
}


.transfer-btn{
margin: 5px;
outline: #0095C8;
color: rgb(255, 166, 0);
background-color: #0000003e;

font-size: 10px;
}


.loading{
 
  width: 300px;
  height: 200px;
  background-color: rgb(120, 119, 119);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
 
 
}