.login-box{
 
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items:center;
   
}

.login-card{
background: #161D26;
border-radius: 10px;
display: flex;
flex-direction: column;
justify-content:space-evenly;
align-items: center;
max-width:500px;
width:90%;
min-height: 90%;
min-width:300px;
}

.loginbtn{
    width: 80%;
    padding: 0.5rem;
    margin:1rem 0px 1rem 0px;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    transition: 1sec;
    background-color: #0095C8;
    border: none;
    outline: none;
    color: white;
    border-radius: 5px;
   
}
.signUpbtn{
    width: 80%;
    padding: 0.5rem;
    margin:1rem 0px 1rem 0px;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    transition: 1sec;
    background-color: #0095C8;
    text-decoration: none;
    text-align: center;
    border: none;
    outline: none;
    color: white;
    border-radius: 5px;  
}

.loginbtn:hover{
    background-image: #027ba4
}
.login-card h3{
    color: rgba(0, 0, 0, 0.534);
    font-weight: 500;
    margin: 1rem;
}

.login-card form{
    display: flex;
    flex-direction: column;
    width: 80%;
    color: whitesmoke;
    
}

.login-card form input{
    margin: 1rem;
    margin-left: 0;
    margin-right: 0;
    padding: 0.5rem;
    outline: none;
    border: none;
    border-bottom: 2px solid rgb(141, 140, 140);
    border-radius: 4px;
    font-weight: 300;
}
.login-card form input:focus{
 border: 2px solid rgb(101, 175, 204);
}

.login-card form .primary{
    width: 100%;
}

.extra-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: rgba(245, 245, 245, 0.753);
    font-size: 14px;
}
.extra-info span{display: flex;flex-direction: row;justify-content: center;align-items: center;cursor: pointer;}

.login-logo{
    color: whitesmoke;
    background-color: #001d2e;
    background-image: linear-gradient(160deg,rgb(5, 87, 119) 0%, #122974  70%);
    padding: 0.5rem;
    border-radius: 100px;
}




.signup-text{
    margin: 1rem;
    text-decoration: none;
    color: whitesmoke;
}

.signup-text:hover{
    color: rgb(214, 214, 214);
}

.extra-info span:hover{
    color: rgb(175, 174, 174);
}


.extra-info-banner{
    flex: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}
@media screen and (max-width: 600px) {
    .extra-info-banner{
        display: none;
    }
  }