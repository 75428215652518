.main{
  
    background-color: #050b1f;
    color: whitesmoke;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
    position: relative;
    min-height: max-content;
}


.main-up{
 
  
    background-color: #001d2e;
    background-image: linear-gradient(160deg,#000000 0%, #090e1f  100%);
  
    min-width: 100%;
   
    box-shadow: 2px 2px 10px #474747;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
   
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    
}

.main-down{
  
  
    min-width: 100%;
    flex-wrap: wrap;
    box-shadow: 2px 2px 10px #474747;
    border-radius: 7px;
    background-color: #001d2e;
    background-image: linear-gradient(160deg,#000000 0%, #090e1f  70%);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 1rem 0px 0px 0px;
   
}
.last-transaction{
    width: 100%;
     border-radius: 5px;
    font-weight: 400;
    text-align: center;
   padding: 4px;
   background-color: #001d2e;
   background-image: linear-gradient(160deg,rgb(5, 87, 119) 0%, #122974  70%);
 
}

.publickey{
    width: 80%;
    border-radius: 5px;
   font-weight: 500;
   text-align: center;

  background-color: #001d2e;
  background-image: linear-gradient(160deg,rgb(8, 19, 24) 0%, #000f2c  70%);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.main-sub-box hr{
    width: 100%;
    height: 2px;
    border: none;
    background-color: #001d2e;
    background-image: linear-gradient(160deg,skyblue 0%, #122974  70%);
}
.no-record{
    margin: 1rem;
}

.main-left{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  
    border-radius: 7px;
  
   
    justify-content: center;
    align-items: center;
   
    
    
}

.main-sub-box{

    margin:1rem 0px 1rem 0px;
   
    border-radius: 2px;
   height: 70px;
width: 180px;
    margin: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #001d2e;
    background-image: linear-gradient(160deg,#090e1f  0%, #111a38 100%);
    box-shadow: 1px 1px 1px 2px #0095C8;
    
   padding: 0.5rem;
}

.webscanner{
    display: flex;
}
.downloadApp{
    display: none;
}


.main-right{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #001d2e;
    background-image: linear-gradient(160deg,#090e1f  0%, #111a38 100%);
    box-shadow: 1px 1px 1px 2px #0095C8;
    border-radius: 7px;
    width: 45vw;
    
    height: 100%;
    min-width:350px;
    min-height:52vh;
    
   

}


.total-coin-title{
    width: 100%;
   height: 30%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #0095C8;
   border-radius: 5px;
   font-weight: 400;
  
}

.total-coin-value{
    width: 100%;
    font-weight: 400;
    height: 70%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   
}

.pn-info{
    max-width: 250px;
}
@media only screen and (max-width: 700px) {
    .main-sub-box{
        width: 80vw;
    }

    .webscanner{
        display: none ;
    }
    .downloadApp{
        display: flex;
    }
  }



  .downloadbtn{
      background-color: #0095C8;
      width: 250px;
      border: none;
      padding: 1rem;
      margin: 1rem;
      font-size: 1rem;
      color: rgb(209, 209, 209);
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
  }

  .downloadbtn:hover{
    background-color: rgb(201, 55, 2);
    
  }

  .chart-btn{
    margin: 0.5rem;
    width: 100px;
    padding: 8px;
    background-color: #142b7895;
    border: 1px solid rgba(255, 166, 0, 0.568);
    color: white;
    border-radius: 8px;

  }

  .chart-btn:hover{
    background-color: rgba(255, 166, 0, 0.768);
  }
