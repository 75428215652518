.sideDrawer{
    position: fixed;
   
    top: -10px;
    left: -400px;
    background-color: #070c24;
    width: 70%;
    height: 100%;
    max-width: 350px;
    max-height: 80vh;
    overflow-y: auto;
    border-radius:3px;
    z-index: 999;
    padding: 1rem;
  box-shadow: 3px 3px 3px 3px #334594;
  
   
   display: flex;
   flex-direction: column;
   justify-content: space-between;
  


    

}

.sidedrawer__box{
    margin-bottom: 1.5rem;
}

.sideDrawer button{
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    transition: 1sec;
    margin-top: 10px;
    margin-bottom: 10px;
  
    border: none;
    outline: none;
    color: white;
    border-radius: 5px;
}

.sideDrawer button:hover{
    transform: scale(1.05);
}

.sidedrawer__box{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
   
  
}


.show{
    position: fixed;
    top: 68px;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0.5s;
    animation: slide 0.5s forwards;
    animation-delay: 0.1s;
}


@-webkit-keyframes slide {
    100% { left:5px; }
}

@keyframes slide {
    100% { left: 5px; }
}

@media only screen and (min-width:600px){
    .sideDrawer{
        max-width: 250px;
        height: min-content;
        min-height: min-content;
    }
}