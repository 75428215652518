.QR-home{
    position: fixed;
    width:600px;
    max-width: 80vw;
    top: -100vh;
    background-color: white;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 9999;
   flex-direction: column;
   color: black;
   font-weight: bold;
   padding: 1rem;
   padding-top: 0px;
   border: 4px solid #0095C8;
   -webkit-animation: slideTop 0.5s forwards;
   -webkit-animation-delay: 0.5s;
   animation: slideTop 0.5s forwards;
   animation-delay: 0.01s;

}

.close-btn{
  background-color: red;
  color: rgb(214, 214, 214);
   
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    font-size: small;
  width: 25px;
  height: 25px;
}

.close-btn:hover{
    transform: scale(1.1);
}

.QR-home p{
    padding: 1rem;
    color: gray;
    font-weight: 500;
}

.QR{
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.QR:hover{
    transform: scale(1.1);
    transition: 0.5s;
    cursor: pointer;
}
.header-Qr{
   width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:1rem;
    
    background-color: #0095C8;
}

.public_key-href{
    text-overflow:ellipsis;
    max-width:90%;
    overflow:hidden;
    white-space:nowrap
}


@-webkit-keyframes slideTop {
    100% { top: 80px; }
}

@keyframes slideTop {
    100% { top: 80px; }
}